import React from "react"
import { graphql } from "gatsby"
// import { GatsbyImage } from "gatsby-plugin-image"

// import * as style from "./single.module.css"
import Layout from "../components/layout"
import Seo from "../components/seo"

const StPage = ({ data }) => {
  const page = data.file
  return (
    <Layout>
      <Seo title={page.childMarkdownRemark.frontmatter.title} />
      <section className={`wrapper`}>
        <h2>{page.childMarkdownRemark.frontmatter.title}</h2>
        <div dangerouslySetInnerHTML={{ __html: page.childMarkdownRemark.html }} />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    file(id: { eq: $id }) {
      childMarkdownRemark {
        html
        frontmatter {
          date
          slug
          title
        }
      }
    }
  }
`

export default StPage
